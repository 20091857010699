import axios from 'axios'
import AppConfig from '../AppConfig'

interface ICollection {
    id: string
    title: string
    archived: boolean
    vaultId: string
}

interface ICollectionResponse {
    data: ICollection
}

interface ICollectionsResponse {
    data: ICollection[]
}

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1/vaults`

export class CollectionService {

    constructor() {
        // set props normally
        // nothing async can go here
    }

    public async getCollections(vaultId: string, authToken: string): Promise<ICollection[]> {
        const url = `${baseUrl}/${vaultId}/collections`
        // try {
        const response = await axios.get<ICollectionsResponse>(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data

        // console.debug(JSON.stringify(data, null, 4))
        // if (isSuccessResponse(response.status)) {
        //     return response.data.data
        // } else {
        //     console.debug('Error message:', response.statusText)
        //     return []
        // }
        // }
        // catch (error) {
        //     console.error('Error:', error)
        //     return []
        // }
    }

    public async createCollection(name: string, vaultId: string, authToken: string): Promise<ICollection> {
        const url = `${baseUrl}/${vaultId}/collections`

        const response = await axios.post<ICollectionResponse>(url, {
            title: name
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken,
            },
            validateStatus: () => true,
        }
        )

        return response.data.data

        // // console.debug(JSON.stringify(data, null, 4))
        // if (isSuccessResponse(response.status)) {
        //     return response.data.data
        // } else {
        //     console.debug('Error message:', response.statusText)
        //     return {} as ICollection
        // }
    }

    public async updateCollection(name: string, collectionId: string, vaultId: string, authToken: string): Promise<ICollection> {
        const url = `${baseUrl}/${vaultId}/collections/${collectionId}`
        // try {
        const response = await axios.patch<ICollectionResponse>(url, {
            title: name
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken,
            },
            validateStatus: () => true,
        }
        )

        return response.data.data

        // // console.debug(JSON.stringify(data, null, 4))
        // if (isSuccessResponse(response.status)) {
        //     return response.data.data
        // } else {
        //     console.debug('Error message:', response.statusText)
        //     return {} as Collection
        // }
    }
}
