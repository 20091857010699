import { JSX } from 'react'
import { NavLink } from 'react-router-dom'

function NotFound(): JSX.Element {
    return (
        <div className="not-found">
            <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8">
                <div className="flex-none flex">
                    <NavLink to="/">
                        <img src="/logo-small.png" width="80" height="26" alt="Logo" />
                    </NavLink>
                </div>
                <div className="w-full max-w-md">
                    <div className="space-y-2 p-6">
                        <h1 className="text-2xl text-center font-medium leading-tight tracking-tight text-white">
                            This page does not exist.
                        </h1>
                        <h2 className="text-2xl text-center font-medium leading-tight tracking-tight text-light-color-dk">404</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
