import { useOutlet } from 'react-router-dom'
import NoSelection from '../components/NoSelection'
import Sidebar from '../components/Sidebar'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

const Content = () => {

    const outlet = useOutlet()

    return (
        <PanelGroup direction="horizontal" className="links">
            <Panel defaultSize={18} minSize={18} className="hidden lg:block" id="sidebar" order={1}>
                <Sidebar />
            </Panel>
            <PanelResizeHandle className="w-px bg-border-dk" />
            <Panel minSize={70} className="border-r border-border-dk" id="contents" order={2}>
                <div className="h-screen overflow-hidden">
                    <div className="content flex h-full flex-col overflow-hidden">
                        {outlet || <NoSelection />}
                    </div>
                </div>
            </Panel>
        </PanelGroup>
    )
}

export default Content
