// import axios from 'axios'
// import AppConfig from '../AppConfig'
// import { Vault } from '../models/Models'
// import { VaultsResponse } from './common'
// import { isSuccessResponse } from '../utilities/response'


// const vaultsUrl = `${AppConfig.host}/${AppConfig.api}/v1/vaults`

export class VaultService {

    public getSelectedVaultId(): string | null {
        return localStorage.getItem('defaultVaultId')
    }

    //     public async getVaults(authToken: string): Promise<Vault[]> {
    //         try {
    //             const response = await axios.get<VaultsResponse>(vaultsUrl,{
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': 'Bearer ' + authToken,
    //                 },
    //                 validateStatus: () => true,
    //             })

    //             // console.debug(JSON.stringify(data, null, 4))
    //             if (isSuccessResponse(response.status)) {
    //                 return response.data.data
    //             } else {
    //                 console.debug('Error message:', response.statusText)
    //                 return []
    //             }
    //         } catch (error) {
    //             console.error('Error:', error)
    //             return []
    //         }
    //     }

}
