import SidebarContents from './SidebarContents'
import AddLinkButton from './AddLinkButton'

function Sidebar() {
    return (
        <div className="sidebar h-screen overflow-hidden font-medium">
            <div className="flex flex-col h-full bg-sidebar-dk">

                <div className="mt-4 px-3 pt-2 flex text-xl items-center">
                    <div className="flex-none flex pl-3">
                        <img src="/logo-small.png" width="60" height="19" alt="Logo" />
                    </div>
                    <div className="ml-auto">
                        <AddLinkButton />
                    </div>
                </div>

                <SidebarContents />

            </div>

        </div>
    )
}

export default Sidebar
