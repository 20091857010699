import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MobileSidebarButton from './MobileSidebarButton'

interface Props {
    query: string | null
}

const SearchToolbar = ({ query }: Props) => {

    const [searchString, setSearchString] = useState<string | null>('')

    const navigate = useNavigate()

    async function handleSearch(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        navigate(`/links/search?q=${searchString}`)
    }

    useEffect(() => {
        setSearchString(query)
    }, [query])

    return (
        <div className="h-18">
            <div className="search-toolbar h-10 py-3 border-border-dk flex items-center border-b px-6 text-white">
                <MobileSidebarButton />
                <div className="controls grow flex items-center text-light-color-dk pl-4 lg:pl-0">
                    <MagnifyingGlassIcon className="h-5 w-5" />

                    <form className="flex items-center h-full w-full"
                        onSubmit={handleSearch}>
                        <input
                            type="text"
                            autoFocus
                            value={searchString ?? ''}
                            placeholder="Search..."
                            autoComplete="off"
                            className="h-full w-full -ml-5 px-6 bg-transparent outline-none text-white placeholder-light-color-dk border-neutral-60 dark:border-neutral-500"
                            onChange={(e) =>
                                setSearchString(e.target.value)
                            }
                        />
                    </form>

                    <button
                        hidden={!searchString}
                        onClick={() =>
                            setSearchString('')
                        }
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </button>
                </div>

            </div>

        </div>
    )
}

export default SearchToolbar
