import { ListBulletIcon, QueueListIcon } from '@heroicons/react/24/outline'
import { Dispatch, SetStateAction } from 'react'

interface Props {
    linksView: string
    setLinksView: Dispatch<SetStateAction<string>>
}

const LinksViewTabControl = ({ linksView, setLinksView }: Props) => {

    return (
        <div className="viewTab flex bg-sbar-highlight-dk gap-1.5 py-0.5 px-0.5 rounded-lg mr-2">
            <button
                onClick={async (e) => {
                    e.stopPropagation()
                    setLinksView('THIN')
                }}
                data-umami-event="show-links-thin-view-button"
                className={`${linksView == 'THIN'
                    ? 'text-white bg-content-dk'
                    : 'hover:text-white'}
                    items-center text-light-color-dk text-sm rounded-lg py-0 px-1` }
            >
                <p className=""><ListBulletIcon className="w-4 h-4" /></p>
            </button >
            <button
                onClick={async (e) => {
                    e.stopPropagation()
                    setLinksView('THICK')
                }}
                data-umami-event="show-links-thick-view-button"
                className={`${linksView == 'THICK'
                    ? 'text-white bg-content-dk'
                    : 'hover:text-white'}
                    items-center text-light-color-dk text-sm rounded-lg py-0 px-1` }
            >
                <p className=""><QueueListIcon className="w-4 h-4" /></p>
            </button>
        </div >
    )
}

export default LinksViewTabControl
