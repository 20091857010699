import { JSX } from 'react'
import Header from './Header'

function Support(): JSX.Element {
    return (
        <div className="support">
            <Header />
            <main>
                <div className="mx-20 my-10 px-8">
                    <div className="w-full">
                        <h1 className="max-w-48 mb-4 mx-auto">Support</h1>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Support
