import SidebarContents from './SidebarContents'
// import { XMarkIcon } from '@heroicons/react/24/outline'
// import { Dispatch, SetStateAction } from 'react'
import AddLinkButton from './AddLinkButton'

// interface Props {
//     setIsOpen: Dispatch<SetStateAction<boolean>>
// }

function SidebarMobile() {
    // function closeModal() {
    //     setIsOpen(false)
    // }

    return (
        <div className="bg-sidebar-dk border-border-dk flex h-full flex-col rounded-r-lg border-r">
            <div className="mt-4 flex px-4 text-xl text-indigo-700">

                <div className="flex-none flex pl-3 flex text-xl items-center">
                    <img src="/logo-small.png" width="60" height="19" alt="Logo" />
                </div>
                <div className="ml-auto">
                    <AddLinkButton />
                    {/* <button
                        className="text-light-color-dk ml-auto mr-1 inline-block rounded border border-transparent align-middle hover:bg-gray-700 hover:text-white focus:outline-none"
                        onClick={closeModal}
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </button> */}
                </div>

            </div>

            <SidebarContents />
        </div>
    )
}

export default SidebarMobile
