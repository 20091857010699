import { observer } from 'mobx-react-lite'
import { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LinksCollection from '../components/LinksCollection'
import LinksToolbar from '../components/LinksToolbar'
import { useStores } from '../stores/RootStore'

const Category = observer(() => {
    const location = useLocation()

    const tokens = location.pathname.split('/')
    const category = tokens[tokens.length - 1]

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    const [linksView, setLinksView] = useState<string>(localStorage.getItem('setting.links.view') ?? 'THIN')

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        console.log('fetch links for category', category)
        linksStore.fetchLinksForCategory(category, authToken)
    }, [linksStore, category])

    useEffect(() => {
        localStorage.setItem('setting.links.view', linksView)
    }, [linksView])

    if (linksStore.loading) {
        console.log('loading links for category')
    } else {
        console.log('display links for category')
    }

    if (linksStore.error) {
        console.log('error links for category')
        return <div>CGI category Error: {linksStore.error}</div>
    }

    return (
        <div className="category flex flex-col h-full">
            <LinksToolbar
                title={category}
                count={linksStore.links.length}
                linksView={linksView}
                setLinksView={setLinksView}
            />

            <LinksCollection
                links={linksStore.links}
                showCollectionName={true}
                showFavoriteStatus={true}
                linksView={linksView}
                isLoading={linksStore.loading}
            />
        </div>
    )
})

export default Category
