import SortLinksControl from './SortLinksControl'
import MobileSidebarButton from './MobileSidebarButton'
import { MagnifyingGlassIcon, EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
import LinksViewTabControl from './LinksViewTabControl'
import { Dispatch, SetStateAction } from 'react'

type ToolBarProps = {
    title: string
    count: number
    linksView: string
    setLinksView: Dispatch<SetStateAction<string>>
}

const LinksToolbar = ({ title, count, linksView, setLinksView }: ToolBarProps) => {

    function capitalizeFirst(str: string | null): string {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        }

        return ''
    }

    return (
        <div className="h-18">
            <div className="toolbar h-10 py-3 border-border-dk flex justify-between items-center gap-4 border-b px-6 text-white">
                <div className="info flex gap-4 items-center text-sm">
                    <MobileSidebarButton />
                    <p className="font-medium">{capitalizeFirst(title)}</p>
                </div>

                <div className="controls flex items-center gap-2 text-light-color-dk">
                    <NavLink to="/links/search">
                        <MagnifyingGlassIcon className="w-5 h-5 hover:text-white" />
                    </NavLink>

                    <div className="mx-3 h-5 w-px bg-gray-700 py-1"></div>

                    <EllipsisHorizontalCircleIcon className="w-5 h-5 hover:text-white" />
                </div>

            </div>

            <div className="flex justify-between items-center text-white text-xs h-8 px-6 border-b border-border-dk">
                <p className="font-light">{count} links</p>

                <div className="flex">
                    <LinksViewTabControl linksView={linksView} setLinksView={setLinksView} />

                    <SortLinksControl />
                </div>
            </div>

        </div>
    )
}

export default LinksToolbar
