import LinkThickView from './LinkThickView'
import LinkThinView from './LinkThinView'
import { useNavigate } from 'react-router-dom'
import { Link } from '../stores/LinksStore'

interface LinkProps {
    link: Link
    showCollectionName: boolean
    showFavoriteStatus: boolean
    linksView: string
}

const LinkRow = ({
    link,
    showCollectionName,
    showFavoriteStatus,
    linksView,
}: LinkProps) => {

    const navigate = useNavigate()

    return (
        <li className=""
            onClick={() => { navigate(`/links/link/${link.id}`) }}>

            {linksView == 'THIN' && (
                <LinkThinView link={link} showFavoriteStatus={showFavoriteStatus} showCollectionName={showCollectionName} />
            )}

            {linksView == 'THICK' && (
                <LinkThickView link={link} showFavoriteStatus={showFavoriteStatus} showCollectionName={showCollectionName} />
            )}

        </li>
    )
}

export default LinkRow
