import React, { useState, useContext, JSX } from 'react'
import { AuthContext } from '../context/AuthContext'
import { NavLink } from 'react-router-dom'

function Register(): JSX.Element {
    const auth = useContext(AuthContext)

    const [email, setEmail] = useState<string>()
    const [registered, setRegistered] = useState<boolean>(false)

    async function handleRegistration(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        const regd = await auth.register(email!)
        if(regd) {
            setRegistered(true)
        }
    }

    return (
        <div className="login">
            <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8">
                <div className="flex-none flex">
                    <NavLink to="/">
                        <img src="/logo-small.png" width="80" height="26" alt="Logo" />
                    </NavLink>
                </div>
                <div className="w-full max-w-md">
                    <div className="space-y-2 p-6">
                        { registered ? (
                            <div>
                                <p className="text-light-color-dk text-normal text-center font-light">
                                    Thank you for registering.<br />
                                    You can now <NavLink className="font-medium text-indigo-500 hover:underline"
                                        to="/login"
                                    >
                                        Login
                                    </NavLink>
                                </p>
                            </div>
                        ) : (
                            <form
                                className="space-y-4 md:space-y-6"
                                onSubmit={handleRegistration}
                            >
                                <h1 className="text-xl text-center font-medium leading-tight tracking-tight text-white">
                                    Register with your email address
                                </h1>
                                <div>
                                    <input
                                        type="email"
                                        data-1p-ignore="true"
                                        name="email"
                                        id="email"
                                        className="block w-full rounded-lg border border-border-dk focus:border-indigo-600 bg-transparent p-3 text-white placeholder-gray-400 focus:outline-none focus:ring-0"
                                        placeholder="Enter your email address..."
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <button
                                    type="submit"
                                    disabled={!email}
                                    data-umami-event="register-button"
                                    className="w-full rounded-lg bg-indigo-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-4 focus:ring-indigo-700"
                                >
                                    Register
                                </button>
                                <p className="text-light-color-dk text-sm text-center font-light">
                                    Already have an account?{' '}
                                    <NavLink className="font-medium text-indigo-500 hover:underline"
                                        to="/login"
                                    >
                                        Login
                                    </NavLink>
                                </p>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
