import { observer } from 'mobx-react'
import { useContext, Dispatch, SetStateAction } from 'react'
import MobileSidebarButton from './MobileSidebarButton'
import { ClipboardIcon, EyeIcon, StarIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid, } from '@heroicons/react/24/solid'
import { ToastContainer, Zoom, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext } from '../context/AuthContext'
import { Link } from '../stores/LinksStore'
import { useStores } from '../stores/RootStore'
import LinksDetailsTabControl from './LinkDetailsTabControl'

interface Props {
    link: Link | null
    detailsView: string
    setDetailsView: Dispatch<SetStateAction<string>>
}

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

const DetailsToolbar = observer(({ link, detailsView, setDetailsView }: Props) => {

    const toastCopyId = link?.url

    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    const handleCopyClick = () => {
        copyTextToClipboard(link?.url ?? '')
        toast('Copied to clipboard', {
            toastId: toastCopyId
        })
    }

    function toggleFavorite(): void {
        if (link != null) {
            linksStore.toggleFavorite(link, authToken)
        }
    }

    return (
        <div className="toolbar h-10 py-3 border-border-dk flex justify-between items-center gap-4 border-b px-6 text-sm text-white">
            <div className="info flex gap-4 items-center">
                <MobileSidebarButton />
                <p className="font-medium">Details</p>
            </div>

            <LinksDetailsTabControl detailsView={detailsView} setDetailsView={setDetailsView} />

            <div className="controls flex items-center gap-4 text-light-color-dk">
                <ToastContainer
                    position="bottom-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="dark"
                    transition={Zoom}
                />

                <button className="hover:text-white flex"
                    onClick={toggleFavorite}
                    title="Favorite">
                    {link?.favorite ? (
                        <StarIconSolid className="h-5 w-5 text-white" />
                    ) : (
                        <StarIcon className="h-5 w-5" />
                    )}
                </button>

                <button className="hover:text-white flex"
                    onClick={handleCopyClick}
                    title="Copy">
                    <ClipboardIcon className="h-5 w-5" />
                </button>

                <a className="hover:text-white flex"
                    target="_blank"
                    rel="external"
                    href={link?.url ?? ''}
                    title="Go to Link">
                    <EyeIcon className="h-5 w-5" />
                </a>
            </div>

        </div>
    )
})

export default DetailsToolbar
