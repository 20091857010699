import axios from 'axios'
import AppConfig from '../AppConfig'

interface ITag {
    id: string
    name: string
    vaultId: string
}

interface ITagsResponse {
    data: ITag[]
}

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1/vaults`

export class TagsService {

    constructor() {
        // set props normally
        // nothing async can go here
    }

    public async getTags(vaultId: string, authToken: string): Promise<ITag[]> {
        const url = `${baseUrl}/${vaultId}/tags`
        // try {
        const response = await axios.get<ITagsResponse>(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data

        //     // console.debug(JSON.stringify(data, null, 4))
        //     if (isSuccessResponse(response.status)) {
        //         return response.data.data
        //     } else {
        //         console.debug('Error message:', response.statusText)
        //         return []
        //     }
        // } catch (error) {
        //     console.error('Error:', error)
        //     return []
        // }
    }
}

