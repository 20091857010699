import { ArchiveBoxIcon, CircleStackIcon, StarIcon, InboxIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'

function Categories() {
    return (
        <div className="categories mt-2">
            <ul className="list-none">
                <li key="all"><NavLink className={({ isActive }) => (isActive ? 'btn-navigation-active' : 'btn-navigation')} to="/links/all"><InboxIcon className="h-5 w-5" /><p className="text-white">All links</p></NavLink></li>
                <li key="favorite"><NavLink className={({ isActive }) => (isActive ? 'btn-navigation-active' : 'btn-navigation')} to="/links/favorites"><StarIcon className="h-5 w-5" /><p className="text-white">Favorites</p></NavLink></li>
                <li key="uncategorized"><NavLink className={({ isActive }) => (isActive ? 'btn-navigation-active' : 'btn-navigation')} to="/links/uncategorized"><CircleStackIcon className="h-5 w-5" /><p className="text-white">Uncategorized</p></NavLink></li>
                <li key="archived"><NavLink className={({ isActive }) => (isActive ? 'btn-navigation-active' : 'btn-navigation')} to="/links/archived"><ArchiveBoxIcon className="h-5 w-5" /><p className="text-white">Archived</p></NavLink></li>
            </ul>
        </div>
    )
}

export default Categories
