import { ChevronRightIcon, StarIcon, FolderIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { useContext, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link } from '../stores/LinksStore'
import { useStores } from '../stores/RootStore'
import ImageWithLoading from './ImageWithLoading'


interface Props {
    link: Link
    showCollectionName: boolean
    showFavoriteStatus: boolean
}

const formatDate = (dateString: string) => {
    const options = {
        month: 'short',
        day: 'numeric',
    } as Intl.DateTimeFormatOptions
    return new Date(dateString).toLocaleDateString('en-us', options)
}

function LinkThickView({ link, showCollectionName, showFavoriteStatus }: Props) {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore, collectionsStore } = useStores()

    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    return (
        <div className="bg-content-highlight-dk hover:bg-sbar-highlight-dk h-20 flex flex-1 flex-row gap-1.5 items-center px-3 border-border-dk border text-sm rounded-xl mx-4 my-2">

            <div className="linkDeco h-16">
                <div className="linkImage w-10 h-10 rounded-md mt-1 overflow-hidden border-border-dk border">
                    <ImageWithLoading src={link?.imageUrl} alt={link?.host} />
                </div>
            </div >

            <div className="linkInfo pl-1 h-16 grow">

                <div className="flex flex-col justify-between h-full">
                    <div className="linkTitle text-white line-clamp-1">
                        <p>
                            {link.title}
                        </p>
                    </div>

                    <div className="linkDesc text-xs text-gray-500 line-clamp-1">
                        {link.description}
                    </div>

                    <div className="lnkProp flex justify-between items-center">
                        <div className="flex items-center">
                            {showCollectionName && (
                                link?.collectionId && (
                                    <>
                                        <div className="hidden sm:inline-flex text-yellow-500 text-xs items-center">
                                            <FolderIcon className="h-4 w-4 pr-1" /> <span>{collectionsStore.getCollectionName(link.collectionId)}</span>
                                        </div>
                                        <p className="hidden sm:inline-flex text-gray-600 px-2">::</p>
                                    </>
                                )
                            )}
                            <p className="text-orange-400 text-xs pr-2">{link.host}</p>
                            <a className="text-light-color-dk hover:text-white rounded-md hover:bg-slate-700/60 p-px ml-1"
                                target="_blank"
                                rel="external"
                                href={link?.url}
                                title="Go to Link"
                                onClick={(e) => e.stopPropagation()}>
                                <ChevronRightIcon className="h-4 w-4" />
                            </a>
                        </div >

                        <div className="flex items-center">
                            <div className="hidden md:inline-flex tags pr-2 text-white items-center">
                                {link?.tags?.map((tag) => (
                                    <div key={tag}
                                        // className="text-green-600 inline-flex px-2 items-center text-xs mr-1 h-6 border rounded-xl border-green-600">
                                        className="text-green-600 inline-flex px-2 items-center text-xs mr-1">
                                        <span className="">{tag}</span>
                                    </div>
                                ))}
                            </div>
                        </div >
                    </div>
                </div>
            </div>

            <div className="linkMeta h-16">
                <div className="flex flex-col justify-between h-full">
                    <div className="favorite ml-auto">
                        {showFavoriteStatus && (
                            <button
                                className="text-light-color-dk ml-auto hover:text-white"
                                disabled={isUpdating}
                                data-umami-event="favorite-link-button"
                                onClick={async (e) => {
                                    setIsUpdating(true)
                                    e.stopPropagation()
                                    await linksStore.toggleFavorite(link, authToken)
                                    setIsUpdating(false)
                                }}
                            >
                                {isUpdating ? (
                                    <div>
                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                ) : (
                                    link.favorite ? (
                                        <StarIconSolid className="h-5 w-5 text-white" />
                                    ) : (
                                        <StarIcon className="h-5 w-5" />
                                    )
                                )}
                            </button>
                        )}
                    </div >
                    <p className="created whitespace-nowrap text-xs text-gray-600">
                        {formatDate(link.createdAt)}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LinkThickView
