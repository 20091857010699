import { JSX } from 'react'
import { NavLink } from 'react-router-dom'

function Home(): JSX.Element {

    return (
        <div className="home">
            <header className="">
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" aria-label="Global">
                    <div className="flex flex-1 justify-end gap-4 items-center">
                        <NavLink
                            className="font-medium text-white"
                            to="/login"
                        >
                            Login
                        </NavLink>

                        {/*<NavLink
                            className="font-medium text-white border border-white rounded-lg px-2 py-1"
                            to="/register"
                        >
                            Register
                        </NavLink> */}
                    </div>
                </nav>
            </header>

            <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8">
                <div className="flex-none flex">
                    <img src="/logo-med.png" width="200" height="64" alt="Logo" />
                </div>
                <div className="w-full mt-8">
                    <div className="space-y-2 p-6">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-white">The easiest way to save your links</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-400">Save all your links in one place, access them from anywhere.</p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 text-sm text-gray-500 mt-8">
                    <p>
                        <NavLink
                            className="font-medium hover:text-white"
                            to="/terms"
                        >Terms of Service</NavLink>
                    </p>
                </div>
                <div className="text-gray-600 text-xs mt-6 pt-2">© Gemt:: 2024</div>
            </div>
        </div>
    )
}

export default Home
