// import { makeAutoObservable } from 'mobx'
import RootStore from './RootStore'
import { VaultService } from '../services/VaultService'


class VaultsStore {
    rootStore: RootStore
    vaultService: VaultService

    // `this` from rootstore passed to the constructor and we can
    // assign it to a variable accessible in this class called
    // `rootStore`. Therefore, we can access other store like
    // useStore for e.g (this.rootStore.userStore)
    constructor(rootStore: RootStore) {
        // makeAutoObservable(this, {
        //     rootStore: false,
        //     vaultService: false
        // })

        this.rootStore = rootStore
        this.vaultService = new VaultService()
    }

    get selectedVault(): string | null {
        return this.vaultService.getSelectedVaultId()
    }
}

export default VaultsStore
