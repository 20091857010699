import { observer } from 'mobx-react-lite'
import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useStores } from '../stores/RootStore'
import { useParams } from 'react-router-dom'
import LinksCollection from '../components/LinksCollection'
import LinksToolbar from '../components/LinksToolbar'

const Collection = observer(() => {
    const { id } = useParams()

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { linksStore, collectionsStore } = useStores()

    const [linksView, setLinksView] = useState<string>(localStorage.getItem('setting.links.view') ?? 'THIN')

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        console.log('fetch links for collection')
        linksStore.fetchLinksForCollection(id!, authToken)
    }, [linksStore, id])

    useEffect(() => {
        localStorage.setItem('setting.links.view', linksView)
    }, [linksView])

    if (linksStore.loading) {
        console.log('loading links for collection')
    } else {
        console.log('display links for collection')
    }

    if (linksStore.error) {
        console.log('error links for collection')
        return <div>CGI collection Error: {linksStore.error}</div>
    }

    return (
        <div className="collection flex flex-col h-full text-white">

            <LinksToolbar
                title={collectionsStore.getCollectionName(id!)}
                count={linksStore.links.length}
                linksView={linksView}
                setLinksView={setLinksView}
            />

            <LinksCollection
                links={linksStore.links}
                showCollectionName={false}
                showFavoriteStatus={true}
                linksView={linksView}
                isLoading={linksStore.loading}
            />

        </div>
    )
})

export default Collection
