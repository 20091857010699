import { useContext, JSX } from 'react'
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'
import Home from './views/Home'
// import About from './views/about/About'
import NotFound from './views/NotFound'
import Login from './views/Login'
import Register from './views/Register'
import Content from './views/Content'
import Terms from './views/Terms'
import Collection from './views/Collection'
import Tag from './views/Tag'
import Category from './views/Category'
import Search from './views/Search'
import Support from './views/Support'
import Auth from './views/Auth'
import LinkDetails from './views/LinkDetails'
// import Pricing from './views/pricing/Pricing'

function PrivateRoutes(): JSX.Element {
    const { isAuthenticated } = useContext(AuthContext)
    const location = useLocation()

    if (!isAuthenticated()) return <Navigate to='/login' replace state={{ path: location.pathname }} />

    return <Outlet />
}

function Router(): JSX.Element {
    return (
        <Routes>
            <Route key="/homepage" path='/' element={<Home />} />
            <Route key="/login" path='/login' element={<Login />} />
            <Route key="/register" path='/register' element={<Register />} />
            <Route key="/support" path='/support' element={<Support />} />
            <Route key="/terms" path='/terms' element={<Terms />} />
            <Route key="/auth" path='/auth/:email/:token' element={<Auth />} />
            {/* <Route key="/pricing" path='/pricing' element={<Pricing />}/> */}
            <Route key="/private" element={<PrivateRoutes />}>
                <Route key="/links" path='/links' element={<Content />}>
                    {['all', 'favorites', 'uncategorized', 'archived'].map((path) => (
                        <Route key={path} path={path} element={<Category />} />
                    ))}
                    <Route key="/links/search/:query" path='/links/search' element={<Search />} />
                    <Route key="/links/collection/:id" path='/links/collection/:id' element={<Collection />} />
                    <Route key="/links/tag/:id" path='/links/tag/:id' element={<Tag />} />
                    <Route key="/links/link/:id" path='/links/link/:id' element={<LinkDetails />} />
                </Route>
                {/* <Route key="/settings" path='/settings' element={<Settings />} /> */}
            </Route>
            <Route key="*" path='*' element={<NotFound />} />
        </Routes>
    )
}

export default Router
