import { Dialog } from '@headlessui/react'
import { ChevronRightIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import React, { useContext, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useStores } from '../stores/RootStore'

const AddCollectionButton = () => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()
    const { collectionsStore } = useStores()

    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [isMutating, setIsMutating] = useState<boolean>(false)
    let [collectionName, setCollectionName] = useState<string>('')
    let collectionNameRef = useRef(null)

    function closeModal() {
        setIsOpen(false)
        setCollectionName('')
    }

    function openModal() {
        setIsOpen(true)
    }

    async function handleAddCollection(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        setIsMutating(true)
        collectionsStore.addCollection(collectionName, authToken)
        closeModal()
        setIsMutating(false)
    }

    return (
        <div className="">
            <button
                onClick={openModal}
                data-umami-event="add-collection-button"
                className="text-light-color-dk flex gap-1.5 p-2 text-sm hover:text-white"
            >
                <PlusCircleIcon className="h-5 w-5" />
                <p className="">New Collection</p>
            </button>

            <Dialog
                open={isOpen}
                onClose={() => { }}
                className="relative z-50"
                initialFocus={collectionNameRef}
            >
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div className="fixed inset-0 bg-black/20" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <Dialog.Panel className="bg-sbar-highlight-dk w-full max-w-md transform overflow-hidden rounded-md text-left align-middle text-white shadow-xl transition-all">
                        <div className="flex flex-row px-3 pt-3">
                            <ChevronRightIcon className="h-5 w-5" />
                            <div className="text-sm">New Collection</div>
                            <button
                                className="text-light-color-dk ml-auto rounded border border-transparent text-xs hover:text-white"
                                onClick={closeModal}
                                data-umami-event="create-collection-button"
                            >
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <form
                            className="space-y-2 pt-1"
                            onSubmit={handleAddCollection}
                        >
                            <div className="flex-column flex px-3">
                                <input
                                    type="text"
                                    data-1p-ignore="true"
                                    ref={collectionNameRef}
                                    name="name"
                                    id="name"
                                    className="bg-sbar-highlight-dk placeholder-light-color-dk block h-8 w-full grow resize-none overflow-hidden py-2 text-white focus:border-0 focus:outline-none focus:ring-0"
                                    placeholder="Collection name"
                                    onChange={(e) => setCollectionName(e.target.value)}
                                />
                            </div>

                            <div className="mt-3 flex flex-row-reverse border-t border-gray-700 px-3 py-3">
                                <button
                                    type="submit"
                                    disabled={isMutating || !collectionName}
                                    className="rounded bg-indigo-700 px-4 py-1.5 text-sm font-medium text-white hover:bg-indigo-600 focus:ring-indigo-700"
                                >
                                    Create Collection
                                </button>
                            </div>
                        </form>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    )
}

export default AddCollectionButton
