import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { observer } from 'mobx-react-lite'
import { Link } from '../stores/LinksStore'
import { useStores } from '../stores/RootStore'

interface Props {
    link: Link | null
}

const LinkInfo = observer(({ link }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    return (
        <div className="link-details mx-6 sm:mx-8 md:mx-16">
            <div
                className="title text-2xl focus:outline-none text-white whitespace-pre-wrap mt-10 mb-3 cursor-text"
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                    linksStore.updateLinkTitle(
                        link!,
                        e.target.innerText,
                        authToken
                    )
                }
            >
                {link?.title}
            </div>

            <div
                className="description editable-content text-slate-300 text-m focus:outline-none whitespace-pre-wrap mb-8 pt-6 p-2 cursor-text"
                data-placeholder="Add a description..."
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                    linksStore.updateLinkDescription(
                        link!,
                        e.target.innerText,
                        authToken
                    )
                }
            >
                {link?.description}
            </div>

            <div className="my-3 w-24 flex-none text-sm text-slate-600">
                Notes
            </div>
            <div
                className="notes editable-content mt-3 p-6 w-full break-all whitespace-pre-wrap text-blue-500 focus:outline-none bg-content-highlight-dk border border-border-dk rounded-lg mb-8 cursor-text"
                data-placeholder="Add a note..."
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                    linksStore.updateLinkNotes(
                        link!,
                        e.target.innerText,
                        authToken
                    )
                }
            >
                {link?.notes}
            </div>
        </div>
    )
})

export default LinkInfo
