import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { TagIcon } from '@heroicons/react/24/outline'
import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { useStores } from '../stores/RootStore'

const Tags = observer(() => {
    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { tagsStore } = useStores()

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        console.log('fetch tags')
        tagsStore.fetchTags(authToken)
    }, [tagsStore])

    if (tagsStore.loading) {
        console.log('loading tags')
    } else {
        console.log('display tags')
    }

    if (tagsStore.error) {
        console.log('error tags')
        return <div>CGI Error: {tagsStore.error}</div>
    }

    return (
        <div className="tags">
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="pl-2 text-light-color-dk flex w-full justify-between text-left text-xs font-semibold">
                            <span>Tags</span>
                            <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="">
                            <ul className="h-full list-none overflow-y-auto">
                                {tagsStore.tags?.map((t) => (
                                    <li key={t.id}>
                                        <NavLink
                                            className={({
                                                isActive,
                                            }) =>
                                                isActive
                                                    ? 'tag-navigation-active'
                                                    : 'tag-navigation'
                                            }
                                            to={`/links/tag/${t.id}`}
                                        >
                                            <TagIcon className="h-5 w-5" />
                                            <p className="">
                                                {t.name}
                                            </p>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
})

export default Tags
