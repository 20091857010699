import { useState } from 'react'
import { PuffLoader } from 'react-spinners'

interface Props {
    src: string
    alt: string
}

const ImageWithLoading = ({ src, alt }: Props) => {
    const [loading, setLoading] = useState(true)

    const handleImageLoad = () => {
        setLoading(false)
    }

    return (
        <div className="image-container w-full h-full">
            {loading && <PuffLoader size={40} color="#4F46E5" />}
            <img
                className="object-cover object-left-top w-full h-full"
                src={src}
                alt={alt}
                onLoad={handleImageLoad}
                style={{ display: loading ? 'none' : 'block' }}
            />
        </div>
    )
}

export default ImageWithLoading
