
function EmptyCollection() {
    return (
        <div>
            <div className="mx-6">
                <div className="py-20 lg:py-32">
                    <h2 className="font-semibold text-center text-[18px] text-light-color-dk">No Links</h2>
                    <p className="text-center text-light-color-dk">Add a link by moving it to the collection or click on the 'Add new link' button.</p>
                </div>
            </div>
        </div>
    )
}

export default EmptyCollection
