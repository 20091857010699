import { useEffect, useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AuthContext } from '../context/AuthContext'
import { useStores } from '../stores/RootStore'
import { useParams } from 'react-router-dom'
import DetailsToolbar from '../components/DetailsToolbar'
import LinkInfo from '../components/LinkInfo'
import LinkMetadata from '../components/LinkMetadata'


const LinkDetails = observer(() => {
    const { id } = useParams()

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    const [detailsView, setDetailsView] = useState<string>('DETAILS')

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        console.log('fetch link details')
        linksStore.fetchLinkDetails(id!, authToken)
    }, [linksStore, id])


    if (linksStore.loading) {
        console.log('loading link details')
    } else {
        console.log('display link details')
    }

    if (linksStore.error) {
        console.log('error link details')
        return <div>CGI link details Error: {linksStore.error}</div>
    }

    return (
        <div className="details flex flex-col sm:flex-row h-full">
            <div className="info sm:flex-1 h-full">
                <DetailsToolbar detailsView={detailsView} setDetailsView={setDetailsView} link={linksStore.link} />

                {detailsView == 'DETAILS' &&
                    <div className="h-[calc(100%-2.5rem)] overflow-y-auto">
                        <div className="pb-4 sm:pb-10">
                            <LinkInfo link={linksStore.link} />
                        </div>

                        {/* // The right sidepane as a bottom pane when in mobile view */}
                        <div className="sm:hidden metadata border-t border-border-dk bg-sidebar-dk">
                            <div className="mx-4 pb-10">
                                <LinkMetadata link={linksStore.link} />
                            </div>
                        </div>
                    </div>
                }
                {detailsView == 'WEBVIEW' &&
                    <div className="h-[calc(100%-2.5rem)]">
                        <iframe src={linksStore.link?.url} loading="lazy" className="w-full h-full"></iframe>
                    </div >
                }
            </div>

            {/* // The right sidepane when in small or greater */}
            <div className="hidden sm:block metadata flex-none w-64 h-full border-l border-border-dk bg-sidebar-dk">
                <div className="mx-4 pb-10">
                    <LinkMetadata link={linksStore.link} />
                </div>
            </div>
        </div>
    )
})

export default LinkDetails
