import { useState, useContext } from 'react'
import { StarIcon, ChevronRightIcon, FolderIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid, } from '@heroicons/react/24/solid'
import { AuthContext } from '../context/AuthContext'
import { Link } from '../stores/LinksStore'
import { useStores } from '../stores/RootStore'


interface Props {
    link: Link
    showCollectionName: boolean
    showFavoriteStatus: boolean
}

const formatDate = (dateString: string) => {
    const options = {
        month: 'short',
        day: 'numeric',
    } as Intl.DateTimeFormatOptions
    return new Date(dateString).toLocaleDateString('en-us', options)
}

const LinkThinView = ({ link, showCollectionName, showFavoriteStatus }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore, collectionsStore } = useStores()

    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    return (
        <div className="hover:bg-content-highlight-dk font-medium border-border-dk border-b text-sm group h-11 items-center flex flex-1 flex-row gap-1.5 py-1 px-6">
            <div className="flex-none border-2 border-indigo-600 rounded-full h-3 w-3 bg-indigo-700"></div>
            <div className="pl-1 lg:w-20 lg:grow">

                <div className="text-white ">
                    <div className="flex items-center">
                        <p className="line-clamp-1">{link.title}</p>
                        <a className="inline-block lg:hidden group-hover:inline-block text-light-color-dk hover:text-white rounded-md hover:bg-slate-700/60 ml-2"
                            target="_blank"
                            rel="external"
                            href={link?.url}
                            title="Go to Link"
                            onClick={(e) => e.stopPropagation()}>
                            <ChevronRightIcon className="h-5 w-5" />
                        </a>
                    </div>
                </div>

            </div>
            <div className="ml-auto">
                <div className="flex flex-row">
                    <div className="hidden md:inline-flex tags pr-2 text-white items-center">
                        {link?.tags?.map((tag) => (
                            <div key={tag}
                                className="text-green-600 inline-flex px-2 h-6 border rounded-xl border-green-600 items-center text-xs mr-1">
                                <span className="">{tag}</span>
                            </div>
                        ))}
                    </div>

                    {showCollectionName && (
                        link?.collectionId && (
                            <div className="hidden md:inline-flex text-yellow-500  h-6 px-2 border rounded-xl border-yellow-500 text-xs items-center mr-2">
                                <FolderIcon className="h-4 w-4 pr-1" /> <span>{collectionsStore.getCollectionName(link.collectionId)}</span>
                            </div>
                        )
                    )}
                    <p className="m-auto hidden whitespace-nowrap text-xs text-gray-600 md:block">
                        {formatDate(link.createdAt)}
                    </p>
                    {showFavoriteStatus && (
                        <button
                            className="text-light-color-dk ml-2 hover:text-white"
                            disabled={isUpdating}
                            data-umami-event="favorite-link-button"
                            onClick={async (e) => {
                                setIsUpdating(true)
                                e.stopPropagation()
                                await linksStore.toggleFavorite(link, authToken)
                                setIsUpdating(false)
                            }}
                        >
                            {isUpdating ? (
                                <div>
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            ) : (
                                link.favorite ? (
                                    <StarIconSolid className="h-5 w-5 text-white" />
                                ) : (
                                    <StarIcon className="h-5 w-5" />
                                )
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default LinkThinView
