import React, { useState, useRef, useContext } from 'react'
import { ChevronRightIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Dialog } from '@headlessui/react'
import { AuthContext } from '../context/AuthContext'
import { useStores } from '../stores/RootStore'
import { useParams } from 'react-router-dom'


const AddLinkButton = () => {
    const { id } = useParams()

    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [isMutating, setIsMutating] = useState<boolean>(false)
    let [linkUrl, setLinkUrl] = useState<string>('')
    let linkUrlRef = useRef(null)

    function closeModal() {
        setIsOpen(false)
        setLinkUrl('')
    }

    function openModal() {
        setIsOpen(true)
    }

    async function handleAddUrl(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        setIsMutating(true)
        await linksStore.addNewLink(linkUrl, authToken, id!)
        closeModal()
        setIsMutating(false)
    }

    console.log('collection id', id)

    return (
        <div className="relative">
            <button
                onClick={openModal}
                data-umami-event="add-link-button"
                className="border border-content-btn-border-dk rounded-md bg-content-btn-dk items-center text-light-color-dk flex w-full gap-1.5 text-sm hover:text-white px-1 py-1 focus:outline-none focus:ring-0">
                <PlusIcon className="h-4 w-4" />
            </button>

            <Dialog
                open={isOpen}
                onClose={() => { }}
                className="relative z-40"
                initialFocus={linkUrlRef}
            >
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div className="fixed inset-0 bg-black/20" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <Dialog.Panel className="w-full max-w-lg transform rounded-md bg-sbar-highlight-dk text-left align-middle shadow-xl transition-all">
                        <div className="flex flex-row px-3 pt-3 text-white">
                            <ChevronRightIcon className="h-5 w-5" />
                            <div className="text-sm">Add Link</div>
                            <button
                                className="text-light-color-dk ml-auto rounded border border-transparent text-xs hover:text-white"
                                onClick={closeModal}
                                data-umami-event="create-link-button"
                            >
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <form
                            className="space-y-2 pt-1"
                            onSubmit={handleAddUrl}
                        >
                            <div className="flex-column flex px-3">
                                <input
                                    type="url"
                                    data-1p-ignore="true"
                                    ref={linkUrlRef}
                                    name="url"
                                    id="url"
                                    className="block h-8 w-full placeholder-light-color-dk grow resize-none overflow-hidden py-2 text-white bg-sbar-highlight-dk focus:border-0 focus:outline-none focus:ring-0"
                                    placeholder="https://"
                                    onChange={(e) => setLinkUrl(e.target.value)}
                                />
                            </div>

                            <div className="flex flex-row justify-between items-center border-t border-gray-700 px-3 py-3">

                                <div className="flex items-center">
                                </div>
                                <button
                                    type="submit"
                                    disabled={isMutating || !linkUrl}
                                    className="rounded bg-indigo-700 px-4 py-1.5 text-sm font-medium text-white hover:bg-indigo-600 focus:ring-indigo-700"
                                >
                                    {isMutating ? (
                                        <p className="w-14">
                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </p>
                                    ) : (
                                        <p className="w-16">Add Link</p>
                                    )}
                                </button>
                            </div>
                        </form>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    )
}

export default AddLinkButton
