import { Collection } from '../stores/CollectionsStore'
import { NavLink } from 'react-router-dom'
import { FolderIcon, PencilIcon } from '@heroicons/react/24/outline'
import { useState, useRef, useEffect, useContext } from 'react'
import { useStores } from '../stores/RootStore'
import { AuthContext } from '../context/AuthContext'

interface Props {
    collection: Collection
}

const CollectionRow = ({ collection }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { collectionsStore } = useStores()

    let [editable, setEditable] = useState<boolean>(false)

    let collectionRef = useRef<HTMLParagraphElement>(null)

    const updateCollection = (name: string) => {
        collectionsStore.updateCollection(collection, name, authToken)
    }

    useEffect(() => {
        collectionRef.current?.focus()
    }, [editable])

    return (
        <li key={collection.id} className="group">
            <NavLink
                className={({
                    isActive,
                }) =>
                    isActive
                        ? 'btn-navigation-active'
                        : 'btn-navigation'
                }
                to={`/links/collection/${collection.id}`}
            >
                <FolderIcon className="flex-none h-5 w-5" />
                <p className="grow text-white"
                    ref={collectionRef}
                    contentEditable={editable}
                    suppressContentEditableWarning={true}
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            setEditable(false)
                            updateCollection(collectionRef.current?.innerText!)
                        }
                    }}
                    onBlur={(e) => {
                        setEditable(false)
                        updateCollection(e.target.innerText)
                    }}>
                    {collection.title}
                </p>
                {!editable && (
                    <button
                        className="text-transparent group-hover:text-gray-500"
                        onClick={() => { setEditable(true) }}
                        data-umami-event="edit-collectionname-button"
                    >
                        <PencilIcon className="h-4 w-4 hover:text-white" />
                    </button>
                )}
            </NavLink>
        </li >
    )
}

export default CollectionRow
