import { makeAutoObservable, runInAction } from 'mobx'
import { SearchService } from '../services/SearchService'
import RootStore from './RootStore'
import { Link } from './LinksStore'

interface ISearchTerm {
    query: string
    searchedAt: Date
}

class SearchStore {
    rootStore: RootStore
    results: any[] = []
    // searches: ISearchTerm[] = []
    loading: boolean = false
    error: any = null
    searchService: SearchService

    // `this` from rootstore passed to the constructor and we can
    // assign it to a variable accessible in this class called
    // `rootStore`. Therefore, we can access other store like
    // useStore for e.g (this.rootStore.userStore)
    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            searchService: false,
            // searches: false
        })

        this.rootStore = rootStore
        this.searchService = new SearchService()
    }


    searchForLinks = async (searchString?: string | null, lang?: string | null, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updateSearchHistory(searchString)

        this.loading = true
        this.error = null

        try {
            const response = await this.searchService.search(vaultId, authToken!, searchString!, lang)
            runInAction(() => {
                console.log('SEARCH', response.length)
                this.results = response.map(l => new Link(
                    l.id,
                    l.url,
                    l.host,
                    l.scheme,
                    l.title,
                    l.description,
                    l.imageUrl,
                    l.notes,
                    l.favorite,
                    l.archived,
                    l.tags,
                    l.vaultId,
                    l.collectionId,
                    l.creatorId,
                    l.indexedAt,
                    l.createdAt,
                    l.modifiedAt,
                ))
                this.loading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.loading = false
            })
        }
    }

    private updateSearchHistory = (searchString: string | null | undefined): void => {
        if (searchString) {
            const history = this.searchHistory
            var search = history.find((el) => el.query === searchString)
            if (search) {
                console.log('search history found ')
                search!.searchedAt = new Date()
            } else {
                console.log('search history NOT found ')
                history.unshift({ query: searchString, searchedAt: new Date() })
            }

            localStorage.setItem('searchHistory', JSON.stringify(history))
        }
    }

    get searchHistory(): ISearchTerm[] {
        const results = localStorage.getItem('searchHistory')
        if (!results)
            return []

        const history: ISearchTerm[] = JSON.parse(results)
        console.log('history parse', history)
        // console.log('getTime', new Date(history[0].searchedAt).getTime())
        return history.sort((a: ISearchTerm, b: ISearchTerm) => new Date(b.searchedAt).getTime() - new Date(a.searchedAt).getTime())
    }
}

export default SearchStore
