import { createContext, ReactNode, useState } from 'react'
import { AuthService } from '../services/AuthService'

interface Props {
    children?: ReactNode;
}

interface IAuthContext{
    loggedIn: boolean; // This is the first state, the email has been registered but not authenticated
    register: (email: string) => Promise<boolean>;
    login: (email: string) => void;
    authorize: (loginToken: string, email?: string) => Promise<boolean>;
    isAuthenticated: () => boolean;
    getAuthToken: () => string | undefined;
}

const initialValue = {
    loggedIn: false,
    register: () => new Promise<boolean>(() => false),
    login: () => {},
    authorize: () => new Promise<boolean>(() => false),
    isAuthenticated: () => false,
    getAuthToken: () => undefined,
}

const AuthContext = createContext<IAuthContext>(initialValue)

function AuthProvider({children}: Props) {
    // Initializing an auth state with false value (unauthenticated)
    const [ loggedIn, setLoggedIn] = useState<boolean>(initialValue.loggedIn)
    const [ loginEmail, setLoginEmail] = useState<string>()

    async function register(email: string): Promise<boolean> {
        const registration = await AuthService.registerUser({email: email})

        if (registration) {
            return true
        } else {
            console.error('Error while registering')
            return false
        }
    }

    async function login(email: string) {
        const result = await AuthService.loginUser({email: email})
        if (result) {
            setLoginEmail(email)
            setLoggedIn(true)
        }
    }

    async function authorize(loginToken: string, email: string | null = null): Promise<boolean> {
        const emailAddr = email ?? loginEmail
        const authorization = await AuthService.authorizeUser({
            email: emailAddr,
            accessToken: loginToken
        })

        if(authorization) {
            localStorage.setItem('authorizationToken', authorization.data.token)
            localStorage.setItem('defaultVaultId', authorization.data.vaultId)

            return true
        } else {
            console.error('Error while authorizing')
            return false
        }
    }


    function isAuthenticated(): boolean {
        const authToken = localStorage.getItem('authorizationToken')
        if (authToken) {
            const payload: any = JSON.parse(atob(authToken.split('.')[1]))
            const expiration = new Date(payload.exp*1000)
            const now = new Date()

            if(expiration.getTime() < now.getTime()){
                console.error('Error token has expired')
                return false
            }

            return true
        }

        return false
    }

    function getAuthToken(): string | undefined {
        return localStorage.getItem('authorizationToken') || undefined
    }

    return (
        <AuthContext.Provider value={{loggedIn, register, login, authorize, isAuthenticated, getAuthToken}}>
            {children}
        </AuthContext.Provider>
    )
}

export {  AuthContext, AuthProvider }
