

function Footer() {
    return (
        <footer className="pt-4 pb-20 border-t border-border-dk">
            <div className="flex justify-between items-center p-4 lg:px-8">
                <div className="text-gray-600 text-xs"><img src="/logo-small.png" width="50" height="16" alt="Logo" /></div>
                <div className="text-gray-600 text-xs">© Gemt:: 2024</div>
            </div>
        </footer>
    )
}

export default Footer
