import { makeAutoObservable, runInAction } from 'mobx'
import RootStore from './RootStore'
import { TagsService } from '../services/TagsService'

export class Tag {
    id: string
    name: string
    vaultId: string

    constructor(id: string, name: string, vaultId: string) {
        makeAutoObservable(this)
        this.id = id
        this.name = name
        this.vaultId = vaultId
    }
}

class TagsStore {
    rootStore: RootStore
    tags: Tag[] = []
    loading: boolean = false
    error: any = null
    tagsService: TagsService

    // `this` from rootstore passed to the constructor and we can
    // assign it to a variable accessible in this class called
    // `rootStore`. Therefore, we can access other store like
    // useStore for e.g (this.rootStore.userStore)
    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            tagsService: false
        })

        this.rootStore = rootStore
        this.tagsService = new TagsService()
    }

    fetchTags = async (authToken?: string) => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.loading = true
        this.error = null

        try {
            const response = await this.tagsService.getTags(vaultId, authToken)

            runInAction(() => {
                this.tags = response.map(t => new Tag(t.id, t.name, t.vaultId))
                this.loading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.loading = false
            })
        }
    }

    getTagName(tagId: string): string {
        if (tagId === null) {
            return ''
        }

        const tag = this.tags.find(
            (ele) => ele.id === tagId
        )
        return tag?.name ?? 'Loading...'
    }
}

export default TagsStore
