import { Dispatch, SetStateAction } from 'react'

interface Props {
    detailsView: string
    setDetailsView: Dispatch<SetStateAction<string>>
}

const LinksDetailsTabControl = ({ detailsView, setDetailsView }: Props) => {

    return (
        <div className="detailsTab flex bg-sbar-highlight-dk gap-1.5 py-0.5 px-0.5 rounded-lg">
            <button
                onClick={async (e) => {
                    e.stopPropagation()
                    setDetailsView('DETAILS')
                }}
                data-umami-event="show-links-details-button"
                className={`${detailsView == 'DETAILS'
                    ? 'text-white bg-content-dk'
                    : 'hover:text-white'}
                    items-center text-light-color-dk text-sm rounded-lg py-0.5 px-2` }
            >
                <p className="">Details</p>
            </button >
            <button
                onClick={async (e) => {
                    e.stopPropagation()
                    setDetailsView('WEBVIEW')
                }}
                data-umami-event="show-links-webview-button"
                className={`${detailsView == 'WEBVIEW'
                    ? 'text-white bg-content-dk'
                    : 'hover:text-white'}
                    items-center text-light-color-dk text-sm rounded-lg py-0.5 px-2` }
            >
                <p className="">Preview</p>
            </button>
        </div >
    )
}

export default LinksDetailsTabControl
