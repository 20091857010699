import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LinksCollection from '../components/LinksCollection'
import SearchToolbar from '../components/SearchToolbar'
import { useStores } from '../stores/RootStore'
import { observer } from 'mobx-react-lite'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const Search = observer(() => {
    const authContext = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const { searchStore } = useStores()

    const navigate = useNavigate()

    const query = searchParams.get('q')
    const lang = searchParams.get('lang')

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        searchStore.searchForLinks(query, lang, authToken)
    }, [query, lang])

    if (!query) {
        return <div className="search flex h-full flex-col">

            <SearchToolbar query={query} />

            <div className="h-[calc(100%-40px)] overflow-y-auto pb-10 pt-3">
                <div className="text-white text-sm pb-3 px-6">Recent searches</div>
                <ul className="list-none text-light-color-dk">
                    {searchStore.searchHistory?.map((search) => (
                        <li key={search.query} className="" onClick={() => { navigate(`/links/search?q=${search.query}`) }}>
                            <div className="flex gap-2 h-8 items-center hover:bg-content-highlight-dk px-6">
                                <div><MagnifyingGlassIcon className="h-3 w-3" /></div>
                                <div className="text-white">{search.query}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

        </div >
    }

    return (
        <div className="search flex h-full flex-col">

            <SearchToolbar query={query} />

            <LinksCollection
                links={searchStore.results}
                showCollectionName={true}
                showFavoriteStatus={true}
                linksView={'THIN'}
                isLoading={searchStore.loading} />

        </div>
    )
})

export default Search
