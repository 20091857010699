import AddCollectionButton from './AddCollectionButton'
import Categories from './Categories'
import Collections from './Collections'
import Tags from './Tags'

function SidebarContents() {
    return (
        <div className="grow overflow-y-auto px-3">
            <ul className="flex h-full flex-col gap-y-2">
                <li key="top">
                    {/* <Vaults selectedVault={selectedVault} setSelectedVault={setSelectedVault} /> */}
                </li>
                <li key="categories">
                    <Categories />
                </li>
                <li key="collections" className="mt-4">
                    <Collections />
                </li>
                <li key="tags" className="mt-4">
                    <Tags />
                </li>
                <li className="flex grow flex-col justify-end pb-3">
                    <AddCollectionButton />
                </li>
            </ul>
        </div>
    )
}

export default SidebarContents
