import { makeAutoObservable, runInAction } from 'mobx'
import { CollectionService } from '../services/CollectionService'
import RootStore from './RootStore'

export class Collection {
    id: string
    title: string
    archived: boolean
    vaultId: string

    constructor(id: string, title: string, archived: boolean, vaultId: string) {
        makeAutoObservable(this)
        this.id = id
        this.title = title
        this.archived = archived
        this.vaultId = vaultId
    }

    toggleArchived() {
        this.archived = !this.archived
    }
}

class CollectionsStore {
    rootStore: RootStore
    collections: Collection[] = []
    loading: boolean = false
    error: any = null
    collectionsService: CollectionService

    // `this` from rootstore passed to the constructor and we can
    // assign it to a variable accessible in this class called
    // `rootStore`. Therefore, we can access other store like
    // useStore for e.g (this.rootStore.userStore)
    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            collectionsService: false
        })

        this.rootStore = rootStore
        this.collectionsService = new CollectionService()
    }

    fetchCollections = async (authToken?: string) => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.loading = true
        this.error = null

        try {
            const response = await this.collectionsService.getCollections(vaultId, authToken)

            runInAction(() => {
                this.collections = response.map(c => new Collection(c.id, c.title, c.archived, c.vaultId))
                this.loading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.loading = false
            })
        }
    }

    addCollection = async (name: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.loading = true
        this.error = null

        try {
            const response = await this.collectionsService.createCollection(name, vaultId, authToken)
            runInAction(() => {
                this.collections.push(new Collection(
                    response.id,
                    response.title,
                    response.archived,
                    response.vaultId
                ))
                this.loading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.loading = false
            })
        }
    }

    updateCollection = async (collection: Collection, name: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.loading = true
        this.error = null

        try {
            await this.collectionsService.updateCollection(name, collection.id, vaultId, authToken)
            runInAction(() => {
                collection.title = name
                this.loading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.loading = false
            })
        }
    }

    getCollectionName(collectionId: string): string {
        if (collectionId === null) {
            return ''
        }

        const collection = this.collections.find(
            (ele) => ele.id === collectionId
        )
        return collection?.title ?? 'Loading...'
    }
}

export default CollectionsStore
