import React, { useState, useContext, JSX, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export default function Login(): JSX.Element {
    const auth = useContext(AuthContext)

    const [email, setEmail] = useState<string>()
    const [loginToken, setLoginToken] = useState<string>()

    const [ authSuccess, setAuthSuccess] = useState<boolean>(true)

    const navigate = useNavigate()

    useEffect(() => {
        if(auth.isAuthenticated()) {
            navigate('/links/all')
        }
    }, [])

    async function handleLogin(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        auth.login(email!)
    }

    async function handleAuthorization(
        e: React.SyntheticEvent<HTMLFormElement>
    ) {
        e.preventDefault()
        const success = await auth.authorize(loginToken!)
        setAuthSuccess(success)
        if (success === true) {
            navigate('/links/all')
        }
    }

    return (
        <div className="login">
            <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8">

                <div className="flex-none flex">
                    <NavLink to="/">
                        <img src="/logo-small.png" width="80" height="26" alt="Logo" />
                    </NavLink>
                </div>

                <div className="w-full max-w-md">
                    <div className="space-y-2 p-6">
                        {!auth.loggedIn && (
                            <form
                                className="space-y-4 md:space-y-6"
                                onSubmit={handleLogin}
                            >
                                <h1 className="text-xl text-center font-medium leading-tight tracking-tight text-white">
                                    What is your email address?
                                </h1>
                                <div>
                                    <input
                                        type="email"
                                        data-1p-ignore="true"
                                        name="email"
                                        id="email"
                                        className="block w-full rounded-lg border border-border-dk focus:border-indigo-600 bg-transparent p-3 text-white placeholder-gray-400 focus:outline-none focus:ring-0"
                                        placeholder="Enter your email address..."
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <button
                                    type="submit"
                                    disabled={!email}
                                    data-umami-event="login-button"
                                    className="w-full rounded-lg bg-indigo-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-4 focus:ring-indigo-700"
                                >
                                    Continue
                                </button>
                                {/* <p className="text-light-color-dk text-sm text-center font-light">
                                    Don’t have an account yet?{' '}
                                    <NavLink className="font-medium text-indigo-500 hover:underline"
                                        to="/register"
                                    >
                                        Register
                                    </NavLink>
                                </p> */}
                            </form>
                        )}
                        {auth.loggedIn && (
                            <form
                                className="space-y-4 md:space-y-6"
                                onSubmit={handleAuthorization}
                            >
                                <div>
                                    <h1 className="text-xl text-center font-medium leading-tight tracking-tight text-white">
                                        Check your email
                                    </h1>
                                </div>
                                <div className="text-white text-center">
                                    <span>
                                        We've sent a temporary log in link.
                                        <br />
                                        Please check your inbox at{' '}
                                        <strong>{email}</strong>
                                    </span>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        data-1p-ignore="true"
                                        autoComplete="false"
                                        spellCheck="false"
                                        name="accessToken"
                                        id="accessToken"
                                        className="block text-center w-full rounded-lg border border-border-dk focus:border-indigo-600 bg-transparent p-3 text-white placeholder-gray-400 focus:outline-none focus:ring-0"
                                        placeholder="Enter code"
                                        onChange={(e) =>
                                            setLoginToken(e.target.value)
                                        }
                                    />
                                </div>
                                { !authSuccess && <span className="text-red-600 text-xs">Please enter a valid verification code</span> }
                                <button
                                    type="submit"
                                    disabled={!loginToken}
                                    data-umami-event="authorize-button"
                                    className="w-full rounded-lg bg-indigo-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-4 focus:ring-indigo-700"
                                >
                                    Continue with login code
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
