import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { observer } from 'mobx-react-lite'
import { useStores } from '../stores/RootStore'
import CollectionRow from './CollectionRow'

const Collections = observer(() => {

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { collectionsStore } = useStores()

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        console.log('fetch collections')
        collectionsStore.fetchCollections(authToken)
    }, [collectionsStore])

    if (collectionsStore.loading) {
        console.log('loading collections')
    } else {
        console.log('display collection')
    }

    if (collectionsStore.error) {
        console.log('error collections')
        return <div>CGI Error: {collectionsStore.error}</div>
    }

    return (
        <div className="collections">
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="pl-2 text-light-color-dk flex w-full justify-between text-left text-xs font-semibold">
                            <span>Collections</span>
                            <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="">
                            <ul className="h-full list-none overflow-y-auto">
                                {collectionsStore.collections?.map((c) => (
                                    <CollectionRow key={c.id} collection={c} />
                                ))}
                            </ul>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
})

export default Collections
